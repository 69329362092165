import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import TextInput from '@oberoninternal/travelbase-ds/components/form/TextInput';
import Modal from '@oberoninternal/travelbase-ds/components/layout/Modal';
import ModalHeader from '@oberoninternal/travelbase-ds/components/layout/ModalHeader';
import { useFormikContext } from 'formik';
import React, { FC, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTenantContext } from '../context/TenantContext';
import { SearchValues } from '../entities/SearchValues';
import { UnitFilterPropertyCategory } from '../generated/graphql';
import useFuse from '../hooks/useFuse';
import { not } from '../utils/fp';
import CheckboxFilter from './CheckboxFilter';
import Formed from './Formed';
import RadioFilter from './RadioFilter';
import Stack from './Stack';
import SearchIcon from './svg/SearchIcon';

interface Props {
    open: boolean;
    onClose: () => void;
    allProperties?: UnitFilterPropertyCategory[];
}

const isPlaceFilter = (val: string) => val.startsWith('_place');
const fuseOpts = { keys: ['name'], threshold: 0.6 };
const SearchPlacesModal: FC<React.PropsWithChildren<Props>> = ({ allProperties, open, onClose }) => {
    const {
        setFieldValue,
        values: { orFilters, andFilters },
    } = useFormikContext<SearchValues>();

    const [search, setSearch] = useState('');
    const placesFilterCategory = useMemo(
        () => allProperties?.find(property => isPlaceFilter(property.handle)),
        [allProperties]
    );

    const {
        brandConfig: { placeFilter },
    } = useTenantContext();
    const filteredFilters = useFuse(placesFilterCategory?.filterProperties ?? [], search, fuseOpts);
    const choices = search ? filteredFilters : placesFilterCategory?.filterProperties ?? [];
    const { formatMessage } = useIntl();

    return (
        <Modal open={open} onClose={onClose} variant="regular">
            <ModalHeader>
                <FormattedMessage defaultMessage="Plaatsen toevoegen" />
            </ModalHeader>

            <Formed
                skipPrompt
                initialValues={{ places: orFilters.filter(isPlaceFilter) }}
                handleSubmit={values => {
                    if (placeFilter?.type === 'AND') {
                        setFieldValue('andFilters', [...andFilters.filter(not(isPlaceFilter)), ...values.places]);
                    } else {
                        setFieldValue('orFilters', [...orFilters.filter(not(isPlaceFilter)), ...values.places]);
                    }
                }}
            >
                {({ submitForm }) => (
                    <Stack spacing={2} mt={4}>
                        <TextInput
                            onChange={e => setSearch(e.currentTarget.value)}
                            placeholder={`${formatMessage({ defaultMessage: 'Zoeken' })  }...`}
                        >
                            <SearchIcon width={22} height={22} />
                        </TextInput>
                        <Stack
                            my={3}
                            spacing={0}
                            px="1px"
                            style={{ maxHeight: 'calc(90vh - 230px)', overflowY: 'auto' }}
                        >
                            {choices.map(place => placeFilter?.type === 'AND' ? (
                                    <RadioFilter
                                        key={place.handle}
                                        id={place.handle}
                                        label={place.name}
                                        categoryHandle={placesFilterCategory?.handle ?? ''}
                                        fieldName="places"
                                        fieldValue={`_place.${place.handle}`}
                                    />
                                ) : (
                                    <CheckboxFilter
                                        key={place.handle}
                                        id={place.handle}
                                        label={place.name}
                                        fieldName="places"
                                        fieldValue={`_place.${place.handle}`}
                                    />
                                ))}
                        </Stack>
                        <Button
                            onClick={() => {
                                onClose();
                                submitForm();
                            }}
                            type="button"
                            style={{ width: '100%' }}
                        >
                            <FormattedMessage defaultMessage="Plaatsen toevoegen" />
                        </Button>
                    </Stack>
                )}
            </Formed>
        </Modal>
    );
};

export default SearchPlacesModal;
